import Button from 'components/inputs/ButtonM';
import Text from 'components/Text';
import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useGState } from 'state/store';
import fetch from 'utils/fetch';
import classNames from 'classnames';
import { Dialog, DialogActions, DialogContent, DialogTitle } from 'components/feedbacks/Dialog';
import SuccessIcon from 'components/svgs/SuccessIcon';
import { useInterval } from 'utils/customHooks';

export default function Scanners() {
  const xdock = useGState((s) => s.branch);
  const { data: scanners = [], refetch } = useQuery(xdock?.id && ['scanners', xdock.id], () =>
    fetch(`/falcon/xdock/device/panel/getScanners?xdockId=${xdock.id}`)
  );
  const [authCode, setAuthCode] = useState('');
  const [registeredDeviceCode, setRegisteredDeviceCode] = useState('');
  const [authCodeDialogOpen, setAuthCodeDialogOpen] = useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const [checkScannerRegistered] = useMutation(
    () =>
      fetch(`/falcon/xdock/device/panel/checkAuthCode?xdockId=${xdock.id}&authCode=${authCode}`),
    {
      onSuccess: ({ deviceCode }) => {
        if (deviceCode) {
          setRegisteredDeviceCode(deviceCode);
          setAuthCodeDialogOpen(false);
          setSuccessDialogOpen(true);
          refetch();
        }
      },
    }
  );

  const [generateCode] = useMutation(
    () =>
      fetch({
        method: 'POST',
        url: '/falcon/xdock/device/panel/register',
        data: {
          xdockId: xdock.id,
        },
      }),
    {
      onSuccess: ({ authCode }) => {
        setAuthCode(authCode);
        setAuthCodeDialogOpen(true);
      },
    }
  );
  const [deregister] = useMutation(
    (deviceId) =>
      fetch({
        method: 'POST',
        url: '/falcon/xdock/device/panel/deregister',
        data: {
          deviceId,
        },
      }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  useInterval(() => {
    if (authCodeDialogOpen) checkScannerRegistered();
  }, 5000);

  return (
    <div class="p-6">
      <div class="flex justify-between items-center">
        <Text class="text-3xl">Scanners</Text>
        <Button variant="contained" onClick={generateCode}>
          Add New
        </Button>
      </div>
      <div class="grid md:grid-cols-3  gap-4 pt-6">
        {scanners.map(
          (details) =>
            details.active && (
              <ScannerCard scanner={details} key={details.id} deregister={deregister}></ScannerCard>
            )
        )}
      </div>
      <Dialog
        open={authCodeDialogOpen}
        onClose={() => setAuthCodeDialogOpen(false)}
        classes={{ paper: 'p-4' }}
      >
        <DialogTitle>
          <Text class="text-base">Enter Code to Register Scanner</Text>
        </DialogTitle>
        <DialogContent class="p-6">
          <Text class="text-3xl text-center">{authCode}</Text>
        </DialogContent>
        <DialogActions class="justify-center flex p-3">
          <Button onClick={generateCode}>Generate Again</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={successDialogOpen}
        onClose={() => setSuccessDialogOpen(false)}
        classes={{ paper: 'p-4' }}
      >
        <DialogContent class="p-6 px-10">
          <div class="flex content-center justify-center pb-2">
            <div>
              <SuccessIcon />
            </div>
          </div>
          <Text class="text-xl font-bold text-center py-4">Scanner Registered</Text>
          <Text class="text-xl text-center">{registeredDeviceCode}</Text>
        </DialogContent>
      </Dialog>
    </div>
  );
}

function ScannerCard({ scanner: { deviceId, deviceCode, busy }, deregister }) {
  return (
    <div class="bg-white rounded-lg px-5 py-4 shadow flex justify-between items-center">
      <div>
        <Text class="font-bold">{deviceCode}</Text>
        <Text
          class={classNames('text-tiny', {
            'text-success': !busy,
            'text-warn': busy,
          })}
        >
          {busy ? 'Busy' : 'Active'}
        </Text>
      </div>
      <button
        onClick={() => deregister(deviceId)}
        class="bg-theme text-white rounded-lg p-2 px-3 text-tiny mt-5 cursor-pointer tooltip"
      >
        Deregister
      </button>
      {/* <Text class="text-tiny">5%</Text> */}
    </div>
  );
}
