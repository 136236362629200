import * as React from 'react';

function SuccessIcon(props) {
  return (
    <svg width={63} height={63} viewBox="0 0 63 63" {...props}>
      <g fill="none" fillRule="evenodd">
        <circle cx={31.5} cy={31.5} r={31.5} fill="#00B366" />
        <path
          stroke="#FFF"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={4}
          d="M21 32l7 6 15-15"
        />
      </g>
    </svg>
  );
}

export default SuccessIcon;
